import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EmployeesState } from '../_reducers/employee.reducer';
import { EmployeeModel } from '../../_models/employe.model';

export const selectEmployeesState = createFeatureSelector<EmployeesState>('employees');

export const currentEmployee = createSelector(
    selectEmployeesState,
    (Employees: EmployeesState) => new EmployeeModel(Employees.selected)
);

export const currentEmployeesList = createSelector(
    selectEmployeesState,
    (Employees: EmployeesState) =>
        Employees.list.data.map((Employee) => new EmployeeModel(Employee))
);

export const currentEmployeesTotal = createSelector(
    selectEmployeesState,
    (Employees: EmployeesState) => Employees.list.total
);

export const currentEmployeesFilters = createSelector(
    selectEmployeesState,
    (Employees: EmployeesState) => Employees.list.filters
);
