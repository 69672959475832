import { createActionGroup, props } from '@ngrx/store';
import { EmployeeModel, EmployeeRequestModel } from '../../_models/employe.model';

export const EmployeesActions = createActionGroup({
    source: 'employees',
    events: {
        'Load Employees': props<{ filters: any }>(),
        'Load Employees Success': props<{
            items: ReadonlyArray<EmployeeModel>;
            total: number;
        }>(),
        'Load Employee': props<{ id: string }>(),
        'Load Employee Success': props<{ data: Readonly<EmployeeModel> }>(),
        'Add Employee': props<{ data: Readonly<EmployeeRequestModel> }>(),
        'Add Employee Success': props<{ data: Readonly<EmployeeModel> }>(),
        'Edit Employee': props<{ id: string; data: Readonly<EmployeeRequestModel> }>(),
        'Edit Employee Success': props<{ id: string; data: Readonly<EmployeeModel> }>(),
        'Remove Employee': props<{ id: string }>(),
        'Remove Employee Success': props<{ id: string }>(),
    },
});