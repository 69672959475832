
import { AttributeModel } from "./attributes.model";
import { BaseModel, ValueModel } from "./base.model";
import { PersonModel } from "./person.model";

export class EmployeeModel extends BaseModel {
    person: PersonModel;
    // attributes: AttributeModel[];
    // type: ValueModel
    // parentEmployee: BaseModel
    constructor(obj: any = null) {
        super(obj);
        this.person = new PersonModel(obj?.person);
        // this.attributes =
        //     obj?.attributes?.map((a: any) => new AttributeModel(a)) || [];
        // this.type = new ValueModel(obj?.type);
        // this.parentEmployee = new BaseModel(obj?.parentEmployee);

    }
}

export class EmployeeRequestModel extends BaseModel {
    person: PersonEmployeeModel;
    type_id: string
    parentEmployeeId: string
    constructor(obj: any = null) {
        super(obj);
        this.person = new PersonEmployeeModel(obj?.person);
        this.type_id = obj?.type_id
        this.parentEmployeeId = obj?.parentEmployeeID

    }
}

export class PersonEmployeeModel extends BaseModel {
    firstname: string;
    lastname: string;
    document: string;
    email: string;
    phone: string;
    type_document_id: string;
    constructor(obj: any = null) {
        super(obj);
        this.firstname = obj?.firstname;
        this.lastname = obj?.lastname;
        this.document = obj?.document;
        this.email = obj?.email;
        this.phone = obj?.phone;
        this.type_document_id = obj?.type_document_id

    }
}

